/*#box-wrapper {
    display: block;
    width: 100%;
}
#form-wrapper {
    float: left;
    width: 20%;
}
#svg-wrapper {
    margin:20px 10px 40px 10px;
}
*/
.box-title {
    padding-left: 25%;
}
label.hole-label {
    font-size: 13px;
}
.py-2_5 {
    padding-top: 0.8rem!important;
    padding-bottom: 0.8rem!important;
}

.pagination {
    display: flex;
    justify-content: flex-end;
}

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.pagination li {
    display: inline-block;
    padding: 10px 15px;
}

.pagination li.previous, .pagination li.next{
    color: #007bff;
}

.pagination li.disabled{
    color: gainsboro;
}

.pagination li.active{
    font-weight: bolder;
    color: white;
    background-color: #007bff;
}
.share-popup .popover {
    min-width: 300px !important;
    max-width: 400px !important;
}
.popover .share-url {
    max-width: 250px;
}
.MuiContainer-maxWidthLg {
    max-width: 100% !important;
}
.custom-side-bar{
    position: relative;
    z-index: 10;
}
.clearfix {
    clear: both;
}
@media (min-width: 600px) {
    .jss10 {
        width: 56px !important;
    }
}